html {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top); */
  height: 100%;
  width: 100%;
  position: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
  width: 100%;
  position: fixed;
}

.toolbar-footer {
  width: 100%;
  height: 3em;
  bottom: 0px;
  position: fixed;
  margin-bottom: constant(safe-area-inset-bottom);
  margin-bottom: env(safe-area-inset-bottom);
}

.adImageCls {
  width: auto;
  height: 3em;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  display: block;
  border: 1px solid black;
}
