.fieldCls {
  width: 80%;
  margin-left: 10% !important;
  margin-right: 10% !important;
}

.fullHeightWidthCls {
  height: 100%;
  width: 100%;
  position: relative;
}

.loginContainer {
  height: 100%;
  width: 100% !important;
  position: fixed;
  max-width: 100% !important;
  background-image: url(./images/loginbackground.jpeg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.loginSubContainer {
  position: relative;
  max-width: 360px !important;
  margin: auto !important;
  background: white;
  padding: 1em;
  margin-top: 7em !important;
  /* border: 2px solid black; */
  border-radius: 10px;
}

.box-shadow {
  -webkit-box-shadow: 0px 10px 20px 0px rgba(50, 50, 50, 0.52);
  -moz-box-shadow: 0px 10px 20px 0px rgba(50, 50, 50, 0.52);
  box-shadow: 0px 10px 20px 0px rgba(50, 50, 50, 0.52);
}

.marginAutoItem {
  margin: auto !important;
  display: block !important;
}

.textCenterCls {
  text-align: center;
}

.titleStyle {
  flex-grow: 1;
}

.hintTextCls {
  font-size: 1.2em;
  flex-grow: 1;
  margin-top:20px;
  text-align: center;
}

.marginTop4Em {
  margin-top: 4em;
}

.marginTop2Em {
  margin-top: 2em !important;
}

.mainCls {
  margin-top: 4em;
  position: relative;
  height: 100%;
  width: 100%;
}

.anchoreBlack {
  text-decoration: none;
  color: black;
}

.listItemCls {
  background: #f50057;
  color: white;
  margin-top: 5px;
  width: 80% !important;
  border-radius: 10px;
  margin-left: 10%;
}

.unitFieldCls {
  max-width: 6em !important;
  min-width: 6em !important;
  width: 6em !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.zeroPaddingCls {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.zeroPaddingLeftCls {
  padding-left: 0px !important;
}

.zeroMarginCls {
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.alignRighCls {
  text-align: right !important;
}

.studentCKEditorCls .ck-editor__editable {
  min-height: 180px !important;
}

.teacherContainer {
  height: 100%;
  width: 100%;
  margin-top: 6em;
}

.leftContainer {
  width: 100%;
  margin-left: 2%;
  padding: 7px;
  float: left;
  height: 86%;
  border: 1px solid black;
  overflow: auto;
  /* padding-bottom: 6em; */
}

.rightContainer {
  width: 45%;
  margin-left: 2%;
  float: right;
  margin-right: 2%;
}

.rightTopContainer {
  height: 49%;
  padding: 7px;
  border: 1px solid black;
  margin-bottom: 1%;
  margin-top: 1%;
  overflow: auto;
}

.rightBottomContainer {
  height: 48%;
  padding: 7px;
  border: 1px solid black;
  margin-bottom: 1%;
  overflow: auto;
}

.leftContainer .ck.ck-editor__main > .ck-editor__editable {
  min-height: calc(100vh - 11em);
}

.rightTopContainer .ck.ck-editor__main > .ck-editor__editable {
  min-height: calc(50vh - 6em);
}

.rightBottomContainer .ck.ck-editor__main > .ck-editor__editable {
  min-height: calc(50vh - 6em);
}

.pull-right{
  float: right;
}

.react-kanban-column{
  overflow: scroll;
  height: 80vh !important;
  width: 30% !important;
}

.export-btn{
  margin-bottom: 10px;
  margin-left: 70%;
}